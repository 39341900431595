import React from 'react'
import Banner from './Banner'
import Benifit from './Benifit'

function Membership() {
  return (
    <>
    <Banner/>
    <Benifit/>
    </>
  )
}

export default Membership
import React from 'react'
import Banner from './Banner'
import "../../../App.css"
import Giftcard from './Giftcard'

function Gift() {
  return (
    <>
    <Banner/>
    <Giftcard/>

    </>
  )
}

export default Gift
import React from 'react'

function Errorpage() {
  return (
    <div>
     <h1> 404 Error</h1>
     <h3> Page Not Found</h3>
    </div>
  )
}

export default Errorpage
